import React from "react"
import Layout from "../components/layout/Layout.js"
import { Row } from "../components/row/Row.js"
import { Section, HeroSection, BelowFold } from "../components/section/Section"
import {
  Title,
  SubHeading,
  Subtitle,
  Heading,
} from "../components/title/Title.js"
import { Column33, Column50 } from "../components/column/Column.js"
import { PrimaryButton, ButtonContainer } from "../components/button/Button.js"
import featuredImage from "../static/contentstrategy.png"
import Seo from "../components/seo"
import { TextMedium } from "../components/text/Text.js"
import { Link } from "../components/link/Link.js"
import img1 from "../static/contentstrategy-when.png"
import CtaBlock from "../blocks/CtaBlock"
import Trusted  from "../blocks/Trusted.js"
import {
  ImageHome,
} from "../components/image/Image.js"
import { Testimonials } from "../components/testimonial/Testimonial.js"


function ContentStrategy({ children, ...rest }) {
  return (
    <Layout>
      <Seo title="Content Strategy" 
      description="Know what content to create, where to distribute it and how to
             manage it with a clear content strategy"
       image={featuredImage}
       featuredImage={featuredImage}/>

      <HeroSection 
        title="Bring clarity to your content chaos" 
        text="Know what content to create, where to distribute it, and how to
        manage it with a clear content strategy." 
        buttonText="Get a quote" 
        buttonLink="/contact/"
        imageUrl={featuredImage}
        imageAlt="Content strategy for SaaS startups"
      />

      <Trusted />

      <Section isCentered>
        <Row isCentered>
        <Subtitle isPrimary subtitle="When should you develop a content strategy?" />
        </Row>
        <Row isCentered>
          <Column50>
            <ImageHome src={img1} alt="homepage" />
          </Column50>
          <Column50>
            <>
            <SubHeading isBold subheading="You create content without a clear direction"
            />
            <TextMedium
              textMedium="You're writing articles and creating new landing pages, but you have no idea where you're going. 
              You don't have a vision and your content isn't producing results."
              />
            </>
            <>
            <SubHeading isBold subheading="Your content isn't aligned to your goals"
            />
            <TextMedium
              textMedium="You don't see any return from your content marketing efforts. Your goals aren't met, and you don't know what to change, when, and why."
              />
            </>
            <>
            <SubHeading isBold subheading="Your content doesn't reflect your offering"
            />
            <TextMedium
              textMedium="Although your offering has changed throughout the years, your approach to content hasn't. As a result, your content is outdated and
               doesn't reflect your brand's value."
              />
            </>
            <>
            <SubHeading isBold subheading="Your have no governance in place" />
            <TextMedium
              textMedium="Your team is constantly asking who's responsible for what, and there are no processes in place. 
              The lack of ownership leads to poor performance and chaos in the team."
              />
            </>
          </Column50>
        </Row>
      </Section>

      
      <BelowFold isLight isCentered>
        <Row isCentered>
        <Subtitle isPrimary subtitle="How will a content strategy help you?" />
        </Row>
        <Row isCentered>
          <Column33>
            <Heading isCentered heading="Know what content to create" />
            <TextMedium textMedium="During the content strategy development, we define your ideal customers, identify their pain points and reasons to buy, and create personas." />
            <TextMedium textMedium="We look at the content types that perform better with each persona and research queries to target based on your goals." />
            {/* <Link
              href="/case-studies/content-types-saas-startups-case-study/"
              anchortext="Read a SaaS case study"
              isCentered
              isPrimary
            /> */}
          </Column33>
          <Column33 isFloating>
            <Heading isCentered heading="Identify your distribution channels" />
            <TextMedium textMedium="Depending on your goals and target audiences, we identify the best channels for promoting your content and define the distribution flows." />
            <TextMedium textMedium="We guide you in implementing automation flows to make your content strategy easier to scale up and manage." />
            {/* <Link
              href="/content-strategy-templates/content-campaign-setup-template/"
              anchortext="See a content campaign template"
              isCentered
              isPrimary
            /> */}
          </Column33>
          <Column33 isFloating>
            <Heading
              isCentered
              heading="Implement processes and a governance model"
            />
            <TextMedium textMedium="We help your team work in a more structured way by developing a governance model that fits your company." />
            <TextMedium textMedium="Based on your CMS, we define and help you implement content lifecycle processes and authoring workflows." />
            {/* <Link
              href="/blog/content-governance-model-global-teams/"
              anchortext="See an example of content governance"
              isCentered
              isPrimary
            /> */}
          </Column33>
        </Row>
      </BelowFold>

      <Section isCentered>
        <Row isCentered>
          <Subtitle isPrimary subtitle="How we work" />
        </Row>
        <Row isCentered>
        <Column50 isFloating>
            <Heading isCentered heading="1. Assess" />
            <TextMedium textMedium="We audit your content, analyze your funnels and content performance, interview your team and identify themes and content gaps." />
          </Column50>
          <Column50 isFloating>
            <Heading isCentered heading="2. Research" />
            <TextMedium textMedium="We research your target audience, competitors, and distribution channels, and select content types and topics for content creation." />
          </Column50>
        </Row>
        <Row isCentered>
          <Column50 isFloating>
            <Heading isCentered heading="3. Plan" />
            <TextMedium textMedium="Based on your goals and resources, we develop a content calendar and recommend workflows and templates for your distribution channels." />
          </Column50>
          <Column50 isFloating>
            <Heading isCentered heading="4. Measure" />
            <TextMedium textMedium="We define content performance metrics and KPIs, provide guidelines for optimization, and help your team create the first content reports." />
          </Column50>
        </Row>
      </Section>

      <Testimonials/>

      <CtaBlock />
    </Layout>
  )
}

export default ContentStrategy
